import React from "react";
import { graphql, useStaticQuery } from "gatsby";

// Components
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../../components/faq/faq-accordion";
import HeroChevron from "../../../components/hero/hero-chevron";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import MortgageCalculator from "../../../components/calculators/mortgage-calculator/calculator";
import HelpSection from "../../../components/home-loans/help-section";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import Icon from "../../../components/custom-widgets/icon";
import CustomLargeCards from "../../../components/card/custom-large-cards";
import NewUIWafdBankBenefits from "../../../components/wafd-bank-benefits/new-ui-wafd-bank-benefits";
import WaFdBuiltPartner from "../../../components/home-loans/wafd-built-partner";
import ReturningBorrowerLink from "../../../components/home-loans/returning-borrower-link";

const HomeLoansConstructionLoans = () => {
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/construction-loans/hero-construction-loan-03-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/construction-loans/hero-construction-loan-03-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/home-loans/construction-loans/hero-construction-loan-03-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/home-loans/construction-loans/hero-construction-loan-03-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/home-loans/construction-loans/hero-construction-loan-03-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/construction-loans/hero-construction-loan-03-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/construction-loans/hero-construction-loan-03-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      CardImage1: file(relativePath: { eq: "cards/articles-cards/thumbnail-buying-land-build-house-071023.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage2: file(
        relativePath: { eq: "cards/articles-cards/thumbnail-construction-to-permanent-loan-010824.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage3: file(relativePath: { eq: "cards/articles-cards/thumbnail-building-vs-buying-061623.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      largeCard1: file(relativePath: { eq: "cards/personalization/thumbnail-closing-costs-03152023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2: file(
        relativePath: { eq: "cards/articles-cards/thumbnail-construction-to-permanent-loan-010824.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const title = "Construction Loan Calculator and Financing";
  const description =
    "Learn about WaFd Bank's construction loans and financing. Check out our construction loan calculator and rates to help you save big on your next project.";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/construction-loans"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-construction-loan-03-250.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "construction-loans-hero",
    ...getHeroImgVariables(pageData),
    altText: "Couple reviewing architectural plans with an architect.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Dream from the ground up with WaFd Bank",
            class: "text-white"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-1",
            text: "Find a Loan Officer",
            url: "/personal-banking/contact-loan-officer",
            class: "btn-white",
            containerClass: "mb-3"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Construction"
    }
  ];

  const refinancingBasicsData = {
    sectionClass: "text-left py-0",
    rowColsClass: "row-cols-1 row-cols-md-3",
    title: "",
    titleClass: "text-center",
    hasGrowEffect: false,
    hasRiseShadowEffect: true,
    useMobileView: false,
    cards: [
      {
        id: "mb-card-1",
        url: "/blog/building-remodeling/buying-land-to-build-house",
        image: {
          imgVariable: pageData.CardImage1.childImageSharp.gatsbyImageData,
          altText: "Wood model house in empty field at sunset."
        },
        title: "7 Tips for Buying Land to Build a House",
        titleClass: "text-green-60",
        text: "Many parts of the country are experiencing housing shortages and experts predict a building boom is just around the corner."
      },
      {
        id: "mb-card-2",
        url: "/blog/building-remodeling/construction-to-permanent-loans-explained",
        image: {
          imgVariable: pageData.CardImage2.childImageSharp.gatsbyImageData,
          altText: "Construction to Permanent Loan Advantages & How They Work"
        },
        title: "Construction-to-Permanent Loans Explained",
        titleClass: "text-green-60",
        text: "It's hard to find everything you want in a home."
      },
      {
        id: "mb-card-3",
        url: "/blog/building-remodeling/building-vs-buying-house",
        image: {
          imgVariable: pageData.CardImage3.childImageSharp.gatsbyImageData,
          altText: "Couple talking about the floor-plan of their new house."
        },
        title: "Building vs Buying a House - Which is Better?",
        titleClass: "text-green-60",
        text: "One of our most commonly asked questions is should I buy or build a house?"
      }
    ]
  };

  const largeCardsImgData = {
    largeCard1: pageData.largeCard1,
    altTextCard1: "Young couple moving in new house, holding keys.",
    largeCard2: pageData.largeCard2,
    altTextCard2: "House project in progress."
  };

  const largeCardsData = [
    {
      title: "Save Up to $600 on Closing Costs With a Checking Account From WaFd Bank*",
      disclaimer:
        "*To qualify for the maximum $600 discount, before closing documents are prepared you must (1) have or open the WaFd checking account, (2) register for EZ-Pay from the new checking account, and (3) sign up for direct deposit of at least one form of a monthly recurring source of income.",
      mainButton: {
        url: "/personal-banking/home-loans/closing-cost-checking-account-offer",
        title: "Find Out How"
      }
    },
    {
      title: "Our Custom Construction Loan Advantage",
      contentListGreenCircleChecked: [
        {
          content: (
            <p id="large-card-2-item-1">
              Save up to $600 on closing costs with a checking account from WaFd Bank.*{" "}
              <a id="large-card-2-item-1-link" href="/personal-banking/home-loans/closing-cost-checking-account-offer">
                Find out how
                <Icon name="arrow-right" class="ml-1" />
              </a>
            </p>
          )
        },
        {
          content: <p id="large-card-2-item-2">We don't sell, assign, or transfer your loan.</p>
        },
        {
          content: (
            <p id="large-card-2-item-3" className="mb-0">
              Once construction is complete, the construction loan automatically becomes a mortgage.
            </p>
          )
        }
      ],
      mainButton: {
        title: "Watch Video"
      },
      videoData: {
        VimeoID: "840195560"
      }
    }
  ];

  const calculatorData = {
    title: "Construction Loan",
    loanType: "Construction",
    loanTerm: "30 Year Fixed",
    homeValue: 350000,
    interestRate: 0,
    estimatedPayment: 0,
    purchasePrice: 400000,
    loanAmount: 100000,
    disclaimersSectionClass: "py-0"
  };

  const helpSectionData = {
    sectionClass: "bg-light",
    text: "If you've done all your research and still want help with your construction loan, just stop by. We're nearby, and we'll sit down with you and answer any questions you may have."
  };

  const faqAccordionData = {
    id: "construction-loans-faq-accordion",
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    faqCategoryNames: ["Construction Loans"]
  };

  return (
    <SecondaryLanding showMortgageNotification={true} loanType="Construction Loans">
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container pb-0">
        <h1>Construction Loans</h1>
        <h3>
          We specialize in home construction loans and work with you and your builder from application to completion.
          Once your home is built, your construction loan automatically becomes a mortgage, so you don't have to worry
          about refinancing or going through more than one approval process.
        </h3>
        <ReturningBorrowerLink loanType="Construction Loan" />
      </section>

      <MortgageCalculator {...calculatorData} />

      <CustomLargeCards noBackground imgData={largeCardsImgData} cardsData={largeCardsData} />

      <WaFdBuiltPartner />

      <NewUIWafdBankBenefits />

      <FaqAccordion {...faqAccordionData} />

      <StretchedLinkCards {...refinancingBasicsData} />

      <HelpSection {...helpSectionData} />
    </SecondaryLanding>
  );
};

export default HomeLoansConstructionLoans;
